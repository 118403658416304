import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonProjectsCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
//import EarningIcon from 'assets/images/icons/earning.svg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';
import useDashboardService from 'services/useDashboardServices';
import { DashboardContext } from '.';
import { gridSpacing } from 'store/constant';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.success.dark,
        borderRadius: '50%',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.success.light,
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const ProjectsCard = ({ isLoading }) => {
    const { projectCountData } = useContext(DashboardContext);
    console.log('count', projectCountData);
    //service call
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading ? (
                <SkeletonProjectsCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={4}>
                            <Box sx={{ px: 2.25, py: 1.5 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {projectCountData?.completedProjectCount ? projectCountData?.completedProjectCount : 0}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.grey[300]
                                            }}
                                        >
                                            Completed Projects
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Box sx={{ p: 2.25 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {projectCountData?.ongoingProjectCount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.secondary[200]
                                            }}
                                        >
                                            Ongoing Projects
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid> */}
                        {/* <Grid item md={3} sm={6} xs={12}>
                            <Box sx={{ p: 2.25 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {projectCountData?.ongoingProjectCount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: theme.palette.secondary[200]
                                            }}
                                        >
                                            Ongoing Projects
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid> */}
                    </Grid>
                </CardWrapper>
            )}
        </>
    );
};

ProjectsCard.propTypes = {
    isLoading: PropTypes.bool
};

export default ProjectsCard;
