import React from 'react';
import useAxiosAuth from 'hooks/useAxiosAuth';

import { handleResponse, handleError } from './response';
const useDashboardService = () => {
    const axiosAuth = useAxiosAuth();
    const getProject = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-projects`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getPartPurchase = async (values) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-partpurchases`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getPartSale = async () => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-partsales`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getExpenses = async (durationType) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-expenses?durationType=${durationType}`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getTodaysInquiryAction = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-todays-inquiry-actions`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getPendingInquiryAction = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/pending-inquiry`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getLowStockList = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/lowstock-list`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const getTommorrowsInquiryAction = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-tommorrows-inquiry-actions`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const getSalesGraph = async (id) => {
        try {
            const response = await axiosAuth.get(`/api/Reports/dashboard-sales-graph`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return {
        getProject,
        getPartPurchase,
        getPartSale,
        getExpenses,
        getLowStockList,
        getTodaysInquiryAction,
        getTommorrowsInquiryAction,
        getSalesGraph,
        getPendingInquiryAction
    };
};

export default useDashboardService;
