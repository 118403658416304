import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';

import Dashboard from 'views/dashboard/index';

// login routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

//Master Imports
const CustomerCategory = Loadable(lazy(() => import('views/masters/customer-category-master/index')));
const Customer = Loadable(lazy(() => import('views/masters/customer-master/index')));
const Vendor = Loadable(lazy(() => import('views/masters/vendor-master/index')));
const ProductCategory = Loadable(lazy(() => import('views/masters/product-category-master/index')));
const Product = Loadable(lazy(() => import('views/masters/product-master/index')));
const PartCategory = Loadable(lazy(() => import('views/masters/part-category-master/index')));
const Part = Loadable(lazy(() => import('views/masters/part-master/index')));
const Service = Loadable(lazy(() => import('views/masters/service-master')));
const User = Loadable(lazy(() => import('views/masters/user-master/index')));
const CompanyDetails = Loadable(lazy(() => import('views/masters/company-details/index')));
const ExpenseCategory = Loadable(lazy(() => import('views/masters/expenses-category/index')));
const Location = Loadable(lazy(() => import('views/masters/location-master/index')));

//Purchase Records

const PurchaseRecords = Loadable(lazy(() => import('views/purchase-records/index')));
const PurchaseRecordForm = Loadable(lazy(() => import('views/purchase-records/PurchaseRecordForm')));

const DebitNote = Loadable(lazy(() => import('views/debit-note/index')));

//Transfer Record
const TransferRecord = Loadable(lazy(() => import('views/transfer-records/index')));
const TransferRecordForm = Loadable(lazy(() => import('views/transfer-records/TransferRecordForm')));

// purchase order
const PurchseOrder = Loadable(lazy(() => import('views/purchase-order')));

// material
const Material = Loadable(lazy(() => import('views/material/index')));
const PoMaterialForm = Loadable(lazy(() => import('views/material/PoMaterialForm')));

//Quotation
const Quotation = Loadable(lazy(() => import('views/quotation/index')));
const QuotationForm = Loadable(lazy(() => import('views/quotation/QuotationForm')));

//Sales Invoice
const SalesInvoice = Loadable(lazy(() => import('views/general-sales-invoice/index')));
const SalesInvoiceForm = Loadable(lazy(() => import('views/general-sales-invoice/GeneralSalesInvoiceForm')));
const ProjectSalesInvoiceForm = Loadable(lazy(() => import('views/project/ongoing/ongoing-project-details/invoice-section/InVoiceForm')));
const SalesReturnInvoice = Loadable(lazy(() => import('views/sales-invoice-return')));
// -------===PROJECT===-------

//Inquiries
const Inquiries = Loadable(lazy(() => import('views/project/inquiries/index')));
const InquiriesForm = Loadable(lazy(() => import('views/project/inquiries/InquiryDataForm')));

//Ongoing Projects
const OngoingProjects = Loadable(lazy(() => import('views/project/ongoing/index')));
const OngoingProjectForm = Loadable(lazy(() => import('views/project/ongoing/OngoingProjectForm')));
const OngoingProjectDetails = Loadable(lazy(() => import('views/project/ongoing/ongoing-project-details/index')));
// const PoMaterialForm = Loadable(lazy(() => import('views/project/ongoing/ongoing-project-details/po-section/PoMaterialForm')));
const PoServiceForm = Loadable(lazy(() => import('views/project/ongoing/ongoing-project-details/po-section/PoServiceForm')));
const ProjectQuotationForm = Loadable(lazy(() => import('views/project/inquiries/quotation-section/ProjectQuotationForm')));

// Amc
const AMCSection = Loadable(lazy(() => import('views/amc/index')));
const AMCDetailsIndex = Loadable(lazy(() => import('views/amc/amc-details/AMCDetailsIndex')));

//Expenses
const Expenses = Loadable(lazy(() => import('views/expenses')));
//Reports
const Reports = Loadable(lazy(() => import('views/reports')));

//Store
const Store = Loadable(lazy(() => import('views/store/index')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole } = useSelector((state) => state.userReducer);
    //const userId = 1;
    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/dashboard',
                element: userId > 0 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/',
                element: userId > 0 ? <Dashboard /> : <Navigate to="/login" />
            },

            // --------------------------------MASTER-----------------------------

            {
                path: '/customer-category',
                element: userId > 0 ? <CustomerCategory /> : <Navigate to="/login" />
            },
            {
                path: '/customer',
                element: userId > 0 ? <Customer /> : <Navigate to="/login" />
            },
            {
                path: '/vendor',
                element: userId > 0 ? <Vendor /> : <Navigate to="/login" />
            },
            {
                path: '/product-category',
                element: userId > 0 ? <ProductCategory /> : <Navigate to="/login" />
            },
            {
                path: '/product',
                element: userId > 0 ? <Product /> : <Navigate to="/login" />
            },
            {
                path: '/part-category',
                element: userId > 0 ? <PartCategory /> : <Navigate to="/login" />
            },
            {
                path: '/part',
                element: userId > 0 ? <Part /> : <Navigate to="/login" />
            },
            {
                path: '/service',
                element: userId > 0 ? <Service /> : <Navigate to="/login" />
            },
            {
                path: '/user',
                element: userId > 0 ? <User /> : <Navigate to="/login" />
            },

            {
                path: '/company-details',
                element: userId > 0 ? <CompanyDetails /> : <Navigate to="/login" />
            },
            {
                path: '/expense-category',
                element: userId > 0 ? <ExpenseCategory /> : <Navigate to="/login" />
            },
            {
                path: '/location',
                element: userId > 0 ? <Location /> : <Navigate to="/login" />
            },

            // ------------------------PurchaseRecords------------------------------
            {
                path: '/purchase-records',
                element: userId > 0 ? <PurchaseRecords /> : <Navigate to="/login" />
            },
            {
                path: '/debit-note',
                element: userId > 0 ? <DebitNote /> : <Navigate to="/login" />
            },
            //
            {
                path: '/purchase-record-form',
                element: userId > 0 ? <PurchaseRecordForm /> : <Navigate to="/login" />
            },
            // ------------------------PurchaseOrders------------------------------
            {
                path: '/purchase-orders',
                element: userId > 0 ? <PurchseOrder /> : <Navigate to="/login" />
            },

            // Transfer Record
            {
                path: '/transfer-record',
                element: userId > 0 ? <TransferRecord /> : <Navigate to="/login" />
            },
            {
                path: '/transfer-record/form',
                element: userId > 0 ? <TransferRecordForm /> : <Navigate to="/login" />
            },
            // ------------------------Material-------------------------------
            {
                path: '/material',
                element: userId > 0 ? <Material /> : <Navigate to="/login" />
            },
            // {
            //     path: '/material-form',
            //     element: userId > 0 ? <MaterialForm /> : <Navigate to="/login" />
            // },
            {
                path: '/po-form-material',
                element: userId > 0 ? <PoMaterialForm /> : <Navigate to="/login" />
            },
            // ------------------------Quotations------------------------------
            {
                path: '/quotation',
                element: userId > 0 ? <Quotation /> : <Navigate to="/login" />
            },
            {
                path: '/quotation-form',
                element: userId > 0 ? <QuotationForm /> : <Navigate to="/login" />
            },
            // ------------------------General Sales Invoice------------------------------
            {
                path: '/sales-return-invoice',
                element: userId > 0 ? <SalesReturnInvoice /> : <Navigate to="/login" />
            },
            {
                path: '/sales-invoice',
                element: userId > 0 ? <SalesInvoice /> : <Navigate to="/login" />
            },
            {
                path: '/sales-invoice-form',
                element: userId > 0 ? <SalesInvoiceForm /> : <Navigate to="/login" />
            },
            // ------------------------Project Sales Invoice------------------------------

            {
                path: '/project-invoice-form',
                element: userId > 0 ? <ProjectSalesInvoiceForm /> : <Navigate to="/login" />
            },
            // ------------------------PROJECT- INQUIRIES------------------------------
            {
                path: '/inquiries',
                element: userId > 0 ? <Inquiries /> : <Navigate to="/login" />
            },
            {
                path: '/inquiry-form',
                element: userId > 0 ? <InquiriesForm /> : <Navigate to="/login" />
            },
            // ------------------------PROJECT- ONGOING PROJECT------------------------------
            {
                path: '/projects',
                element: userId > 0 ? <OngoingProjects /> : <Navigate to="/login" />
            },
            {
                path: '/ongoing-project-form',
                element: userId > 0 ? <OngoingProjectForm /> : <Navigate to="/login" />
            },
            {
                path: '/ongoing-project-details',
                element: userId > 0 ? <OngoingProjectDetails /> : <Navigate to="/login" />
            },

            {
                path: '/po-form-service/*',
                element: userId > 0 ? <PoServiceForm /> : <Navigate to="/login" />
            },
            {
                path: '/Project-quotation-form/*',
                element: userId > 0 ? <ProjectQuotationForm /> : <Navigate to="/login" />
            },
            // -------------------------AMC--------------------------
            {
                path: '/amc/',
                element: userId > 0 ? <AMCSection /> : <Navigate to="/login" />
            },
            {
                path: '/amc-details/',
                element: userId > 0 ? <AMCDetailsIndex /> : <Navigate to="/login" />
            },
            // ------------------------Reports------------------------------
            {
                path: '/reports',
                element: userId > 0 ? <Reports /> : <Navigate to="/login" />
            },
            // ------------------------Expenses------------------------------
            {
                path: '/expenses',
                element: userId > 0 ? <Expenses /> : <Navigate to="/login" />
            },
            // ------------------------Store------------------------------
            {
                path: '/store',
                element: userId > 0 ? <Store /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
