import { setUser } from '../store/userSlice';
import { axiosAuth, axiosOpen } from '../services/apiBase';

//Third party imports
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const useAxiosAuth = () => {
    const { userId, userName, role, displayRole, accessToken } = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        // localStorage.removeItem('accessToken');
        dispatch(
            setUser({
                userId: 0,
                profileId: 0,
                userName: '',
                role: 0,
                displayRole: '',
                locationId: 0
            })
        );
        navigate('/login');
    };

    useEffect(() => {
        axiosAuth.interceptors.request.use(async (req) => {
            let accessToken = localStorage.getItem('accessToken');
            console.log('accessToken', accessToken);
            if (accessToken) {
                req.headers['Authorization'] = `Bearer ${accessToken}`;
                const decodedToken = jwt_decode(accessToken);
                const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
                if (!isExpired) return req;
                let request = {
                    accessToken: accessToken
                };

                try {
                    const response = await axiosOpen.post('/api/Authentication/refresh-token', request);
                    if (response.data.status == true) {
                        localStorage.setItem('accessToken', response.data.accessToken);
                        req.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
                        return req;
                    } else {
                        alert('Your session exired, login again.');
                        handleLogout();
                        return;
                    }
                } catch (error) {
                    alert('Your session exired, login again.');
                    handleLogout();
                    return;
                }
            } else {
                handleLogout();
                return;
            }
        });

        // return () => {
        //   axiosAuth.interceptors.request.eject(requestIntercept);
        // };
    }, [accessToken]); //add auth
    return axiosAuth;
};

export default useAxiosAuth;
