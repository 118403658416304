import useAxiosAuth from '../hooks/useAxiosAuth';
import { axiosOpen } from './apiBase';
import { handleResponse, handleError } from './response';

const useAuthenticationService = () => {
    const axiosAuth = useAxiosAuth();

    const changePassword = async (values) => {
        try {
            const response = await axiosAuth.post(`/api/Authentication/changepassword`, values);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const sendOtp = async (email) => {
        try {
            const response = await axiosOpen.post(`/api/Authentication/send-password-reset-otp?emailAddress=${email}`);

            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const verifyOtp = async (otp, email) => {
        try {
            const response = await axiosOpen.post(`/api/Authentication/verify-otp?otp=${otp}&EmailAddress=${email}&isForgotPassword=true`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const login = async (email, password) => {
        try {
            let request = {
                emailAddress: email,
                password: password
            };
            const response = await axiosOpen.post('/api/Authentication/login', request);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    const checkAppVersion = async () => {
        try {
            const response = await axiosOpen.get(`/api/Authentication/v1/application-version`);
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };
    return { changePassword, sendOtp, verifyOtp, login, checkAppVersion };
};

export default useAuthenticationService;
