import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './CompletedProjectCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import useDashboardService from 'services/useDashboardServices';
import ProjectsCard from './ProjectsCard';
import InquiryProjectCard from './InquiryProjectCard';
import CanceledInquiriesProjectCard from './CanceledInquiriesProjectCard';
import TommorrowsInquiryCard from './TommorrowsInquiryCard';
import PieChartExpenses from './PieChartExpenses';
import PendingInquiryCard from './PendingInquiryActions';
import LowStockList from './LowStockList';

export const DashboardContext = React.createContext();
// ==============================|| DEFAULT Dashboard ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const { userId, displayName, role } = useSelector((state) => state.userReducer);

    //service
    const {
        getProject,
        getPartPurchase,
        getPartSale,
        getExpenses,
        getTodaysInquiryAction,
        getTommorrowsInquiryAction,
        getSalesGraph,
        getPendingInquiryAction,
        getLowStockList
    } = useDashboardService();

    const [projectCountData, setProjectCountData] = useState();
    const [partPurchaseData, setPartPurchaseData] = useState();
    const [partsellData, setPartSellData] = useState();
    const [todaysInquiryData, setTodayInquiryData] = useState();
    const [tommarowsInquiryData, setTomarrowsInquiryData] = useState();
    const [salesGarphData, setSalesGarphData] = useState();
    const [pendingInquiryData, setPendingInquiryData] = useState();
    const [lowStockListData, setLowStockListData] = useState();

    const navigate = useNavigate();
    useEffect(() => {
        setLoading(false);
        if (userId == 0) {
            navigate('/login');
        }
    }, []);
    const getAllDataFromApi = () => {
        Promise.all([
            getProject(),
            getPartPurchase(),
            getPartSale(),
            getTodaysInquiryAction(),
            getTommorrowsInquiryAction(),
            getSalesGraph(),
            getPendingInquiryAction(),
            getLowStockList()
        ])
            .then(
                ([
                    projectResponse,
                    partPurchaseResponse,
                    partSaleResponse,
                    todaysInquiryResponse,
                    tomarrowsInquiryResponse,
                    salesGraphResponse,
                    pendingInquiryResponse,
                    lowStockListResponse
                ]) => {
                    setProjectCountData(projectResponse.data);
                    setPartPurchaseData(partPurchaseResponse.data);
                    setPartSellData(partSaleResponse.data);
                    setTodayInquiryData(todaysInquiryResponse.data.inquiryActions);
                    console.log('tomarrow', todaysInquiryResponse.data);
                    setTomarrowsInquiryData(tomarrowsInquiryResponse.data.inquiryActions);
                    setSalesGarphData(salesGraphResponse.data);
                    setPendingInquiryData(pendingInquiryResponse.data.projectInquiryActions);
                    setLowStockListData(lowStockListResponse.data.parts);
                    console.log('pending', lowStockListResponse.data.parts);
                }
            )
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getAllDataFromApi();
    }, []);

    console.log('first', pendingInquiryData);
    return (
        <DashboardContext.Provider
            value={{
                projectCountData,
                partPurchaseData,
                partsellData,
                todaysInquiryData,
                tommarowsInquiryData,
                salesGarphData,
                pendingInquiryData,
                lowStockListData
            }}
        >
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        {/* lg={4} md={6} sm={6} */}
                        <Grid item xs={12} sm={6} md={3}>
                            <ProjectsCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TotalOrderLineChartCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <InquiryProjectCard isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CanceledInquiriesProjectCard isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={8}>
                            <TotalGrowthBarChart isLoading={isLoading} />
                        </Grid>

                        <Grid item xs={12} sm={8} md={4}>
                            <TotalIncomeDarkCard isLoading={isLoading} />
                            <TotalIncomeLightCard isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <PopularCard isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TommorrowsInquiryCard isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={12}>
                                    <PendingInquiryCard isLoading={isLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <PieChartExpenses isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={12}>
                                    <LowStockList isLoading={isLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
