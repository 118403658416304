// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconAerialLift,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconAerialLift,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney
};

const adminMenuItems = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: icons.IconDashboard,
                url: '/dashboard',
                breadcrumbs: false
            },
            {
                id: 'master',
                title: 'Masters',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-category',
                        title: 'Customer Category',
                        type: 'item',
                        url: '/customer-category',
                        breadcrumbs: false
                    },
                    {
                        id: 'customer',
                        title: 'Customers',
                        type: 'item',
                        url: '/customer',
                        breadcrumbs: false
                    },
                    {
                        id: 'vendor',
                        title: 'Vendors',
                        type: 'item',
                        url: '/vendor',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'product-category',
                    //     title: 'Product Categories',
                    //     type: 'item',
                    //     url: '/product-category',
                    //     breadcrumbs: false
                    // },
                    // {
                    //     id: 'product',
                    //     title: 'Products',
                    //     type: 'item',
                    //     url: '/product',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'part-category',
                        title: 'Part Categories',
                        type: 'item',
                        url: '/part-category',
                        breadcrumbs: false
                    },
                    {
                        id: 'part',
                        title: 'Parts',
                        type: 'item',
                        url: '/part',
                        breadcrumbs: false
                    },
                    {
                        id: 'service',
                        title: 'Services',
                        type: 'item',
                        url: '/service',
                        breadcrumbs: false
                    },
                    {
                        id: 'user',
                        title: 'Users',
                        type: 'item',
                        url: '/user',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'account',
                    //     title: 'Account',
                    //     type: 'item',
                    //     url: '/account',
                    //     breadcrumbs: false
                    // },
                    {
                        id: 'company-details',
                        title: 'Company Details',
                        type: 'item',
                        url: '/company-details',
                        breadcrumbs: false
                    },
                    {
                        id: 'expense-category',
                        title: 'Expense Categories',
                        type: 'item',
                        url: '/expense-category',
                        breadcrumbs: false
                    },
                    {
                        id: 'location',
                        title: 'Locations',
                        type: 'item',
                        url: '/location',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'material',
                title: 'Purchase Orders',
                type: 'item',
                icon: icons.IconNotes,
                url: '/material',
                breadcrumbs: false
            },
            {
                id: 'Purchases',
                title: 'Purchases',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'debit-notes',
                        title: 'Purchase Records',
                        type: 'item',
                        icon: icons.IconBrandMastercard,
                        url: '/debit-note',
                        breadcrumbs: false
                    },
                    {
                        id: 'purchase-records',
                        title: 'Purchase Returns',
                        type: 'item',
                        icon: icons.IconBrandMastercard,
                        url: '/purchase-records',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'transfer-records',
                title: 'Transfer Records',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/transfer-record',
                breadcrumbs: false
            },
            // {
            //     id: 'purchase-orders',
            //     title: 'Purchase Orders',
            //     type: 'item',
            //     icon: icons.IconBrandMastercard,
            //     url: '/purchase-orders',
            //     breadcrumbs: false
            // },

            {
                id: 'quotation',
                title: 'Quotations',
                type: 'item',
                icon: icons.IconNotes,
                url: '/quotation',
                breadcrumbs: false
            },
            {
                id: 'Sales',
                title: 'Sales',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'sales-invoice',
                        title: 'Sales Invoices',
                        type: 'item',
                        icon: icons.IconReportMoney,
                        url: '/sales-invoice',
                        breadcrumbs: false
                    },
                    {
                        id: 'sales-return-invoice',
                        title: 'Sales Return Invoices',
                        type: 'item',
                        icon: icons.IconReportMoney,
                        url: '/sales-return-invoice',
                        breadcrumbs: false
                    }
                ]
            },

            {
                id: 'project',
                title: 'Projects',
                type: 'collapse',
                icon: icons.IconFileInvoice,
                breadcrumbs: false,
                children: [
                    {
                        id: 'inquiries',
                        title: 'Inquiries',
                        type: 'item',
                        url: '/inquiries',
                        breadcrumbs: false
                    },
                    {
                        id: 'projects',
                        title: 'Projects',
                        type: 'item',
                        url: '/projects',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'amc',
                title: 'AMC',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/amc',
                breadcrumbs: false
            },
            {
                id: 'expenses',
                title: 'Expenses',
                type: 'item',
                icon: icons.IconNotes,
                url: '/Expenses',
                breadcrumbs: false
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                icon: icons.IconReportAnalytics,
                url: '/reports',
                breadcrumbs: false
            },
            {
                id: 'store',
                title: 'Stores',
                type: 'item',
                icon: icons.IconReportAnalytics,
                url: '/store',
                breadcrumbs: false
            }
        ]
    }
    // {
    //     id: 'main-settings',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'setting-pages',
    //             title: 'Settings',
    //             type: 'collapse',
    //             icon: icons.IconSettings,
    //             breadcrumbs: false,
    //             children: [
    //                 {
    //                     id: 'user',
    //                     title: 'User',
    //                     type: 'item',
    //                     //icon: icons.IconUsers,
    //                     url: '/user',
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'company',
    //                     title: 'Company',
    //                     type: 'item',
    //                     url: '/company',
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'account',
    //                     title: 'Account',
    //                     type: 'item',
    //                     url: '/account',
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'service',
    //                     title: 'Service',
    //                     type: 'item',
    //                     url: '/service',
    //                     breadcrumbs: false
    //                 },
    //                 // {
    //                 //     id: 'potc-template',
    //                 //     title: 'POTC Template',
    //                 //     type: 'item',
    //                 //     url: '/potc-template',
    //                 //     breadcrumbs: false
    //                 // },
    //                 // {
    //                 //     id: 'salestc-template',
    //                 //     title: 'Sales TC Template',
    //                 //     type: 'item',
    //                 //     url: '/salestc-template',
    //                 //     breadcrumbs: false
    //                 // },
    //                 {
    //                     id: 'stage',
    //                     title: 'Stage',
    //                     type: 'item',
    //                     url: '/stage',
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'operating-expenses-category',
    //                     title: 'Operating Expenses Category',
    //                     type: 'item',
    //                     url: '/operating-expenses-category',
    //                     breadcrumbs: false
    //                 }
    //                 // {
    //                 //     id: 'quotation',
    //                 //     title: 'Quotation',
    //                 //     type: 'item',
    //                 //     url: '/quotation',
    //                 //     breadcrumbs: false
    //                 // }
    //             ]
    //         }
    //     ]
    // }
];

export default adminMenuItems;
