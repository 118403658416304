import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Snackbar, Stack, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import loginImage from 'assets/images/login-image.png';
// import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import useAuthenticationService from 'services/useAuthenticationService';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

//No internet ALERT
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { checkAppVersion } = useAuthenticationService();
    const { userId, userName, roleDisplay } = useSelector((state) => state.userReducer);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [open, setOpen] = useState(false);

    let version = localStorage.getItem('version');

    const timeOut = 3 * 60 * 1000;

    const showInternetError = () => {
        if (!isOnline) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    useEffect(() => {
        showInternetError();
    }, [isOnline]);
    // Handle online/offline events to update the isOnline state
    const handleConnectionChange = () => {
        setIsOnline(navigator.onLine);
    };

    // Set up event listeners for online/offline events
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    const caching = () => {
        checkAppVersion().then((response) => {
            let version = localStorage.getItem('version');
            console.log(version);
            if (version == null || version == undefined) {
                localStorage.setItem('version', response.data);
            }
            if (version != response.data) {
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach((name) => {
                            caches.delete(name);
                        });
                    });

                    // Makes sure the page reloads. Changes are only visible after you refresh.
                    window.location.reload(true);
                }

                localStorage.clear();
                localStorage.setItem('version', response.data);
            }
        });
    };

    useEffect(() => {
        caching();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            caching();
        }, timeOut);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

    return (
        <>
            <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error">
                    <Typography variant="h4" color="white">
                        Oops! You are offline
                    </Typography>
                    <Typography variant="h5" color="white">
                        Please check your internet connection and kindly refresh this page after a successful network connection
                    </Typography>
                </Alert>
            </Snackbar>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 290,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, alignItems: 'center' }}>
                    <LogoSection />
                    <Stack>
                        <Typography variant="h4" sx={{ marginLeft: 2 }}>
                            The Dezire Elevators
                        </Typography>
                        {/* <Typography variant="h6" sx={{ marginLeft: 2 }}>
                            AGRO INDUSTRIES
                        </Typography> */}
                    </Stack>
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', margin: 'auto' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search
            <SearchSection />*/}
            <Box sx={{ flexGrow: 1 }} />
            <Stack alignItems="center" sx={{ marginLeft: 2 }}>
                <Typography variant="h2" color="secondary">
                    Dezire Portal
                </Typography>
                <Typography variant="caption" color="primary">
                    Version {version}
                </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            {/* this is to add space in between*/}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <Stack align="center" sx={{ marginRight: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}>
                <Typography variant="h5">{userName}</Typography>
                <Typography variant="h6" color="primary" align="left">
                    ({roleDisplay})
                </Typography>
            </Stack>

            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
