import Chart from 'react-apexcharts';
import SkeletonPopularCard from 'components/Skeleton/SkeletonComponent';
import MainCard from 'ui-component/cards/MainCard';
import { CardContent, Grid, Avatar, Menu, MenuItem, useTheme, TextField, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '.';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import GetAppTwoToneIcon from '@mui/icons-material/CalendarMonth';
import useDashboardService from 'services/useDashboardServices';
const status = [
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'Quarter',
        label: 'This Quarter'
    },
    {
        value: 'year',
        label: 'This Year'
    }
];
function PieChartExpenses({ isLoading }) {
    const { getExpenses } = useDashboardService();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('month');
    const [type, setType] = useState(0);
    const [ExpensesData, setExpensesData] = useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //  setExpensesData(expensesReponse.data);
    const handleClose = () => {
        setAnchorEl(null);
    };
    // Sample data and chart options
    const chartData = {
        options: {
            chart: {
                type: 'pie'
            },
            labels: Array.isArray(ExpensesData?.topExpenses) && ExpensesData?.topExpenses.map((ele) => ele.title)
        },
        series: Array.isArray(ExpensesData?.topExpenses) && ExpensesData?.topExpenses.map((ele) => ele.amount)
    };
    // ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
    console.log('piee', ExpensesData?.topExpenses);
    useEffect(() => {
        getExpenses(type)
            .then((response) => {
                console.log('pie', response);
                setExpensesData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [type]);
    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false}>
                    <CardContent sx={{ height: 400 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h4">Expenses</Typography>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <TextField id="standard-select-currency" select value={value} onChange={(e) => setValue(e.target.value)}>
                                    {Array.isArray(status) &&
                                        status.map((option, i) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                onClick={() => {
                                                    setType(i);
                                                    handleClose();
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="chart" style={{ marginTop: 0 }}>
                                    <Chart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="pie"
                                        height={300}
                                        width={'100%'}
                                        marginRight={10}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
}

export default PieChartExpenses;
