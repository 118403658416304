import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
const PrintContext = createContext();
const PrintContextProvider = ({ children }) => {
    const [printCmd, setPrintCmd] = useState(false);
    const componentRef = useRef();

    const onPrintComplete = () => {
        setPrintCmd(false);
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: onPrintComplete
    });
    const printStart = () => {
        setPrintCmd(true);
    };
    useEffect(() => {
        if (printCmd) handlePrint();
    }, [printCmd]);
    return (
        <PrintContext.Provider value={{ printCmd, handlePrint, onPrintComplete, printStart, componentRef }}>
            {children}
        </PrintContext.Provider>
    );
};

const usePrintOperation = () => {
    return useContext(PrintContext);
};

export { PrintContextProvider, usePrintOperation };
