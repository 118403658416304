import PropTypes from 'prop-types';
import { Fragment, useContext, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Button,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

// project imports
import BajajAreaChartCard from './BajajAreaChartCard';
import MainCard from 'ui-component/cards/MainCard';
// import SkeletonPendingInquiryCard from 'ui-component/cards/Skeleton/PendingInquiryCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from 'store/constant';

// assets
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { DashboardContext } from '.';
import NoDataFound from 'components/NoData/NoDataFound';
import { convertDateToLocal } from 'utils/DateOperations';
// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PendingInquiryActions = ({ isLoading }) => {
    const { pendingInquiryData } = useContext(DashboardContext);

    const tableHeading = useMemo(
        () => [{ title: '#', width: 50 }, { title: 'Project Name' }, { title: 'Date' }, { title: 'Created By' }],
        []
    );

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    console.log('pendingInquiryData', pendingInquiryData);
    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <MainCard content={false}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container alignContent="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h4">Pending Inquiry Action</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="column">
                                        <TableContainer
                                            sx={{
                                                border: 'solid',
                                                borderWidth: 1,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <Table size="small">
                                                <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                    <TableRow>
                                                        {tableHeading.map((row, index) => (
                                                            <>
                                                                <TableCell key={index} width={row.width != undefined ? row.width : null}>
                                                                    <Typography variant="h5">{row.title}</Typography>
                                                                </TableCell>
                                                            </>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {Array.isArray(pendingInquiryData) && pendingInquiryData[0] == undefined ? (
                                                        <NoDataFound />
                                                    ) : (
                                                        Array.isArray(pendingInquiryData) &&
                                                        pendingInquiryData.map((ele, i) => {
                                                            return (
                                                                <TableRow
                                                                    key={i}
                                                                    sx={{
                                                                        backgroundColor: i % 2 === 0 ? 'white' : theme.palette.grey[50]
                                                                    }}
                                                                >
                                                                    <TableCell sx={{ width: 40 }}>{i + 1}</TableCell>
                                                                    <TableCell> {ele.projectName}</TableCell>
                                                                    <TableCell>{convertDateToLocal(ele.followupDate)}</TableCell>
                                                                    <TableCell>{ele.createdByName}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </MainCard>
                </div>
            )}
        </>
    );
};

PendingInquiryActions.propTypes = {
    isLoading: PropTypes.bool
};

export default PendingInquiryActions;
