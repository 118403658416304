import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import adminMenuItems from 'menu-items/adminMenuItems';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { role } = useSelector((state) => state.userReducer);
    // const role = 1;
    let menuItemsTobAdded = [];
    // if (role == 0) {
    //     menuItemsTobAdded = doctorMenuItems;
    // } else if (role == 1) {
    //     menuItemsTobAdded = frontDeskMenuItems;
    // } else if (role == 2) {
    //     menuItemsTobAdded = technicianMenuItems;
    // } else if (role == 3) {
    //     menuItemsTobAdded = ipdStaffMenuItems;
    // } else if (role == 4) {
    //     menuItemsTobAdded = accountsMenuItems;
    // } else if (role == 5) {
    //     menuItemsTobAdded = adminMenuItems;
    // }
    menuItemsTobAdded = adminMenuItems;

    const navItems = menuItemsTobAdded.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
